import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import styled from 'styled-components';

const SocialFollow = () => {
    return (
        <SocialContainer>
            <a
                href="https://www.youtube.com/channel/UCZSV8eqans29oUk2RwcK-IQ"
                target="_blank" rel="noopener noreferrer"
                className="youtube"
            >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>
            <a
                href="https://www.facebook.com/Tripleaim-Software-102655991791845"
                target="_blank" rel="noopener noreferrer"
                className="facebook"
            >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href="https://www.twitter.com/tripleaimSoftw1"
                target="_blank" rel="noopener noreferrer"
                className="twitter"
            >
                <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
            <a
                href="https://www.linkedin.com/company/tripleaim-software"
                target="_blank" rel="noopener noreferrer"
                className="linkedin"
            >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
        </SocialContainer>
    );
}

export default SocialFollow

const SocialContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    @media screen and (min-width: 800px) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;        
    }

    @media screen and (min-width: 1000px) {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        align-items: center;     
    }

    a {
        transition: transform 250ms;
        display: inline-block;
    }

    a:hover {
        transform: translate(-2px)
    }

    a.facebook {
        color: #4968ad;
    }
    
    a.linkedin {
        color: #4968ad;
    }

    a.youtube {
        color: #eb3223;
    }
    
    a.twitter {
       color: #49a1eb;
    }

`