import React from 'react'
import styled from 'styled-components';
import { Link } from "gatsby"
import { HiOutlinePhone } from 'react-icons/hi'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { HiOutlineMail } from 'react-icons/hi'
import SocialFollow from './SocialFollow'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrapper>
                <ContactCard>
                    <ContactHeader>Contact Information</ContactHeader>
                    <TextWrap>
                        <IconImg><HiOutlinePhone /></IconImg>
                        <ContactInfo>0792 640 973</ContactInfo>
                        {/* <ContactInfo>/ 0726 115 048</ContactInfo> */}
                    </TextWrap>
                    <TextWrap>
                        <IconImg><HiOutlineMail /></IconImg>
                        <ContactInfo>support@patameds.com</ContactInfo>
                    </TextWrap>
                    <TextWrap>
                        <IconImg><HiOutlineLocationMarker /></IconImg>
                        <ContactInfo>Address</ContactInfo>
                    </TextWrap>
                    <LocationT>Reality Building Kiambu Road</LocationT>
                </ContactCard>
                <QuickLinks>
                    <LinksHeader>Quick Links</LinksHeader>
                    <FooterLinks>
                        <FooterLink to="/afyabook">Afyabook</FooterLink>
                        <FooterLink to="/patameds">Patameds</FooterLink>
                        <FooterLink to="/blog">Blog</FooterLink>
                        <FooterLink to="/contact">Contact</FooterLink>
                        {/* <FooterLink to="/website">Website</FooterLink>
                        <FooterLink to="/software-application">Software Application</FooterLink>
                        <FooterLink to="/digital-marketing">Digital Marketing</FooterLink>
                        <FooterLink to="/usecase">Usecase</FooterLink> */}
                        {/* <FooterLink to="/Terms-of-use">Terms of Use</FooterLink>
                        <FooterLink to="/Privacy-and-policy">Privacy and Policy</FooterLink> */}
                    </FooterLinks>
                </QuickLinks>
                <SocialLinks>
                    <Linktitle>Keep in touch</Linktitle>
                    <SocialFollow />
                </SocialLinks>
            </FooterWrapper>
            <CopyrightText>Copyright Tripleaim Software 2021</CopyrightText>
        </FooterContainer>
    )
}

export default Footer

const FooterContainer = styled.div`
    min-height: 30vh;
    padding: 1rem 1rem;
    background: #2F2F2F;
    color: #fff;
`
const FooterWrapper = styled.div`
    display:grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    width: 100%    
    padding: 0 2rem;

    @media screen and (min-width: 800px) {
        grid-template-columns: 2fr 2fr 1fr;
        grid-gap: 20px;
        
        }

    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px;
        }
`
const ContactCard = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: transparent; 
    border: 2px solid #6F6E6E;
`
const ContactHeader = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem;
`
const TextWrap = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
`
const IconImg = styled.div`
    font-size: clamp(1.25rem,2vw, 1.5rem);
    position: absolute;
    top: -6px;
    left: -20px;
`

const ContactInfo = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    padding: 0rem 0.5rem;
`
const LocationT = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    width: 70%;
`

const QuickLinks = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: transparent; 
    border: 2px solid #525252;
    line-height: 2rem;
`

const LinksHeader = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem;
`
const FooterLinks = styled.div`
    display: flex;
    flex-direction: column;
`
const FooterLink = styled(Link)`
    text-align: center;
    font-size: clamp(1rem,2vw, 1.25rem);
    &:hover {
        color: #fff;
    }
`
const SocialLinks = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: transparent; 
    border: 2px solid #403F3F;
    line-height: 2rem;
`
const Linktitle = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem;
`
const CopyrightText = styled.div`
    text-align: center;
    font-size: clamp(0.5rem,2vw, 1rem);
    padding: 1rem;
    `
