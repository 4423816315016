import React, { useState } from "react"
import { Link } from "gatsby"
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa'
import NavSideBar from "./NavSideBar";
import logo from "../images/logo.svg";


const Header = () => {
  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  return (
    <Nav>
      <NavLink to='/' onClick={closeMobileMenu}>
        <img src={logo} alt="logo" css={`width: 100px; height: 100px`} />
      </NavLink>
      <Bars onClick={handleClick} />
      {click && (
        <NavSideBarStyle>
          <NavSideBar />
        </NavSideBarStyle>
      )}
      <NavMenu>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about/">About Us</Link></li>
        <li>
          <div>Products ▾</div>
          <ul className="dropdown">
            <li><Link to="/patameds/">Patameds</Link></li>
            <li><Link to="/afyabook/">Afyabook</Link></li>
            {/* <li><Link to="/website/">Website</Link></li>
            <li><Link to="/digital-marketing/">Digital Marketing</Link></li> */}
          </ul>
        </li>
        {/* <li><Link to="/usecase/">Use Case</Link></li>
        <li><Link to="/portfolio/">Portfolio</Link></li> */}
        <li><Link to="/blog/">Blog</Link></li>
        <li><Link to="/contact/">Contact</Link></li>
      </NavMenu>
    </Nav>
  )
}

export default Header

const Nav = styled.nav`
  background: #fff;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem;
  z-index: 999;
  position: fixed;
  width: 100%;
  top: 0;
`

const NavLink = styled(Link)`
    display: flex;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;  
`
const Bars = styled(FaBars)`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 75%);
  font-size: 1.8rem;
  cursor: pointer;
  color: #5EA3B8;
  
  @media screen and (min-width: 900px) {
    display: none;
  }
`

const NavSideBarStyle = styled.div`
  width: 100%;
  background: #000;
  height: auto;
  position: absolute;
  top: 66px;
  z-index: 140;
  right: 0px;
  transition: all 0.5s ease;
  
  @media screen and (min-width: 900px) {
    display: none;
  }
`

const NavMenu = styled.ul`
  display: none;

@media screen and (min-width: 900px) {
  display: flex;
  align-items: center; 
  list-style: none; 

  li {
    display: inline block;
    position: relative;
    line-height: 21px;
    text-align: left;
  }

  li a {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #000;
  }
  
  li div {
    color: #000;
    cursor: pointer;
    padding: 0.5rem 1rem;
  }

  li a:hover {
    color: #fff;
    background: #939393;
    transition: all 0.2s ease-out;
  }
  li div:hover {
    color: #fff;
    background: #939393;
    transition: all 0.2s ease-out;
  }
  
  li ul.dropdown {
    min-width: 100%; 
    background: #f2f2f2;
    display: none;
    position: absolute;
    z-index: 200;
    left: 0;
    top: 35px;
  }

  li:hover ul.dropdown {
    display: block;
  }

  li ul.dropdown li {
    display: block
  }
}
`

