import React from 'react'
import styled from 'styled-components';
import { Link } from "gatsby"


const NavSideBar = () => {
  return (
    <NavMenu>
      <li><Link to="/">Home</Link></li>
      <li><Link to="/about/">About Us</Link></li>
      <li>
        <btn>Services ▾</btn>
        <ul className="dropdown">
          <li><Link to="/patameds/">Patameds</Link></li>
          <li><Link to="/afyabook/">Afyabook</Link></li>
          {/* <li><Link to="/website/">Website</Link></li>
          <li><Link to="/digital-marketing/">Digital Marketing</Link></li> */}
        </ul>
      </li>
      {/* <li><Link to="/usecase/">Use Case</Link></li>
      <li><Link to="/portfolio/">Portfolio</Link></li> */}
      <li><Link to="/blog/">Blog</Link></li>
      <li><Link to="/contact/">Contact</Link></li>
    </NavMenu>
  )
}

export default NavSideBar

const NavMenu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start; 
  justify-content: center;
  list-style: none; 

  li {
    display: inline block;
    position: relative;
    line-height: 31px;
    text-align: left;
    width: 100%;
  }

  li a {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #fff;
  }
  
  li btn {
    color: #fff;
    cursor: pointer;
    padding: 1rem 1rem;
  
  }

  li a:hover {
    color: #fff;
    background: #939393;
  }
  li btn:hover {
    color: #fff;
    background: #939393;
    padding: 1rem 1rem;
  }
  
  li ul.dropdown {
    background: transparent;
    display: none;
    position: absolute;
    z-index: 200;
    left: 110px;
    top: -9px;
  }

  li:hover ul.dropdown {
    display: block;
  }

  li ul.dropdown li {
    display: block
  }

`
